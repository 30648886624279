  import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    title: 'Home',
    component: () => import('@/layouts/main'),
    children: [
      {
        path: '',
        name: 'list_video',
        component: () => import('@/pages/client/videos/index'),
      },

        {
          path: 'video/:id',
          name: 'video_item',
          component: () => import('@/pages/client/videos/item'),
        },
        {
          path: 'video/album/:id',
          name: 'video_album',
          component: () => import('@/pages/client/videos/album'),
        },
        {
          path: '/settings',
          name: 'settings',
          component: () => import('@/pages/client/settings/index'),
        },

        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/pages/client/profile/index'),
          children: [

            {
              path: '',
              name: 'account',
              component: () => import('@/pages/client/profile/account')
            },

            {
              path: 'password',
              name: 'password',
              component: () => import('@/pages/client/profile/psw')
            },

            {
              path: 'person',
              name: 'person',
              component: () => import('@/pages/client/profile/person')
            },

            {
              path: 'right',
              name: 'right',
              component: () => import('@/pages/client/profile/right')
            },

            {
              path: 'settings',
              name: 'settings',
              component: () => import('@/pages/client/profile/settings')
            },

            {
              path: 'service',
              name: 'service',
              component: () => import('@/pages/client/profile/service')
            }
          ]
        }

    ]
  },

  {
    path: '/admin',
    name: 'Admin',
    title: 'Админ панель',
    component: () => import('@/layouts/Scoped/Admin/Page'),
    children: [
      {
        path: '',
        name: 'Videos',
        component: () => import('@/pages/admin/video/list'),
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@/pages/admin/users/list')
      },


      {
        path: 'video/:id',
        name: 'video',
        component: () => import('@/pages/admin/video/item'),
      },
      {
        path: 'video/album/:id',
        name: 'video_albumb',
        component: () => import('@/pages/admin/video/album'),
      },
      {
        path: 'video/edit/:id',
        name: 'video_edit',
        component: () => import('@/pages/admin/video/edit'),
      },
      {
        path: 'video/add',
        name: 'home',
        component: () => import('@/pages/admin/video/add')
      },
    ]
  },

  {
    path: '/auth',
    name: 'Auth',
    title: 'Авторизация',
    component: () => import('@/layouts/auth'),
    children: [
      {
        path: '',
        name: 'auth',
        component: () => import('@/pages/auth/auth')
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('auth_token')

  if (to.name === 'auth' && !token) {
    next()
  } else if (token) {
    next()
  } else {
    next({name: 'auth'})
  }

})

export default router
